define("cheddargorge/templates/whats-on/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "6I+/5xfr",
    "block": "[[[1,[34,0]],[1,\"\\n\\n\"],[6,[39,1],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n  \"],[1,[34,2]],[1,\"\\n\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Cheddar Gorge and Caves events\"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[6,[39,1],[\"quartz-with-top\"],null,[[\"default\"],[[[[41,[33,5],[[[1,\"\\n      \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,2],[12],[1,\"\\n          Come and see what’s happening at Cheddar Gorge and Caves in 2025\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n\\n\"],[42,[28,[37,8],[[28,[37,8],[[33,5]],null]],null],null,[[[1,\"\\n          \"],[8,[30,1,[\"item\"]],null,[[\"@cardImage\",\"@ctaLink\",\"@ctaLinkModel\",\"@ctaUrl\",\"@ctaLinkLabel\",\"@ctaIsExternalLink\"],[[30,2,[\"cardImage\"]],\"whats-on.event\",[30,2,[\"slug\"]],[30,2,[\"ctaLink\"]],[30,2,[\"ctaLabel\"]],[30,2,[\"isExternalLink\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[10,0],[14,0,\"template--title\"],[12],[1,\"\\n              \"],[1,[30,2,[\"name\"]]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"template--dates\"],[12],[1,\"\\n              \"],[1,[30,2,[\"dates\"]]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"template--caption\"],[12],[1,\"\\n              \"],[1,[30,2,[\"cardCaption\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"]],[]]]]],[1,\"\\n\\n\"]],[2]],null],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n\"]],[]],[[[1,\"      \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n        We currently have no events scheduled for the immediate future, but do check back again!\\n      \"]],[]]]]],[1,\"\\n\"]],[]]]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[1,[34,9]]],[\"card\",\"event\"],false,[\"find-it-fast\",\"ui-background\",\"global-nav-buffer\",\"section-intro\",\"if\",\"model\",\"events-cards\",\"each\",\"-track-array\",\"global-footer\"]]",
    "moduleName": "cheddargorge/templates/whats-on/index.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});